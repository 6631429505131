import {
	FilterProduct,
	ProductActualQuantity,
	ProductCollectionJson,
	ProductJson,
	ProductRangePrice,
	ProductRating,
	ProductSample,
	ProductShow,
	ProductShowWeb,
	ProductTagJson,
	ProductTagsCollectionJson,
} from "@/types/Product.type";
import ProductConstants from "@/common/constants/product";
import Helper from "@/utils/helper";
import { callApiV2 } from "@/lib/config/Api";
import { Filter, FilterProps } from "@/common/types/Filter.type";
import { BrandCollectionJson, FilterBrand } from "@/types/Brand.type";

const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;
const BASE_URL_V1 = process.env.REACT_APP_BASE_URL;
const URL_PREV_API = BASE_URL_V2 + "/products/public";

export async function getListProduct(
	filters: FilterProduct,
	timeOut?: number,
	showPromotionPrice: 1 | 0 = 1
) {
	const show: ProductShow = filters.show ? filters.show : "web";
	const apiParams = Helper.convertFilterToParams({
		...filters,
		// show_web: ProductShowWeb.ALLOW_SALE,
		show_promotion_price: showPromotionPrice,
		show,
	});
	console.log(apiParams)
	const res = await callApiV2<ProductCollectionJson>(
		`${URL_PREV_API}${apiParams}`,
		"get",
		{
			timeout: timeOut,
		}
	);

	return res;
}
export async function getProductDetail(
	id: number | string,
	store_id: number,
	params?: { parent_id: number }
) {
	const res = await callApiV2<ProductJson>(
		`${URL_PREV_API}/${id}?store_id=${store_id}`,
		"get",
		{
			params: params,
		}
	);
	return res;
}
export async function getListProductRelated(
	id: number | string,
	filters: FilterProduct
) {
	const apiParams = Helper.convertFilterToParams({ ...filters, id: id });
	const res = await callApiV2<ProductCollectionJson>(
		`${URL_PREV_API}/related${apiParams}`,
		"get",
		{
			revalidate: 30,
		}
	);
	return res;
}
export async function getListProductVariant(
	FilterProps: FilterProps<{
		id: number | string;
		tags: string;
		store_id: number;
	}>
) {
	const props = FilterProps.filters;
	let newParams = JSON.parse(JSON.stringify(props));
	if (props.tags.length <= 0) {
		delete newParams.tags;
	}
	const apiParams = Helper.convertFilterToParams(newParams);

	const res = await callApiV2<ProductCollectionJson>(
		`${URL_PREV_API}/related${apiParams}`,
		"get",
		{
			...FilterProps.config,
		}
	);
	return res;
}
export async function getTagsProductDetails(
	filters: FilterProps<{ product_id: number }>
) {
	const params = filters.filters;
	const res = await callApiV2<ProductTagJson[]>(
		`${URL_PREV_API}/group`,
		"get",
		{
			params: params,
			...filters.config,
			revalidate: 60,
		}
	);
	return res;
}
export async function getRateProductClient(
	id: string | number,
	type: string = "product"
) {
	const response = await callApiV2<ProductRating>(
		"/comments/public/rate",
		"get",
		{
			params: {
				item_type: type,
				item_id: id,
				host: location.hostname,
			},
		}
	);
	return response;
}
export function getProductsRecentViewToLocal() {
	if (typeof window !== "undefined") {
		const dataLocal =
			localStorage.getItem(ProductConstants.PRODUCT_RECENT_VIEW.KEY) || "[]";
		const listData: number[] = JSON.parse(dataLocal);

		if (Array.isArray(listData)) {
			return listData;
		} else {
			return [];
		}
	}
	return [];
}
export function addProductRecentViewToLocal(id: number) {
	if (typeof window !== "undefined") {
		const list = getProductsRecentViewToLocal();
		const index = list.findIndex((item) => item === id);
		if (index >= 0) {
			list.splice(index, 1);
		}
		list.unshift(id);

		const len = list.length;
		if (len > ProductConstants.PRODUCT_RECENT_VIEW.LIMIT_NUMBER_SAVE) {
			list.pop();
		}
		localStorage.setItem(
			ProductConstants.PRODUCT_RECENT_VIEW.KEY,
			JSON.stringify(list)
		);
	}
}
export async function getProductTagsSize(filter: Filter) {
	const res = await callApiV2<ProductTagsCollectionJson>(
		process.env.REACT_APP_BASE_URL + `/tags/public/allsize?type=7`,
		"get",
		{
			params: filter,
		}
	);
	return res;
}
export async function getMinMaxRangeFilter(collectionId?: number | string) {
	const res = await callApiV2<ProductRangePrice>(
		URL_PREV_API + `/getprice`,
		"get",
		{
			params: collectionId ? { collection_id: collectionId } : {},
		}
	);

	return res;
}
export async function getBrandsFilter(filter: Partial<FilterBrand>) {
	const queryData = Helper.convertFilterToParams(filter);
	const res = await callApiV2<BrandCollectionJson>(
		process.env.REACT_APP_BASE_URL + `/brands/public${queryData}`,
		"get"
	);
	return res;
}

export async function getProductSample(params: { type: string }) {
	const res = await callApiV2<ProductSample>(
		BASE_URL_V2 + `/products/getsample`,
		"get",
		{
			params: params,
			timeout: 1000 * 10,
		}
	);
	return res;
}

export async function getActualQuantity(params: { product_ids: string }) {
	const res = await callApiV2<ProductActualQuantity[]>(
		BASE_URL_V1 + `/inventories/public/product/actual/quantity`,
		"get",
		{
			params: params,
		}
	);
	return res;
}
